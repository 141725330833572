import React,{ Component,useState } from 'react'
import Background from '../images/background.jpg'
import Reader from './Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode,faSearch } from '@fortawesome/free-solid-svg-icons'
import { Container,Card,Nav } from 'react-bootstrap'
import QrReader from 'react-qr-reader'
import Search from './Search'
// import {success} from '../helpers/alerts'
import { useHistory } from "react-router-dom";

function Presencas(){

    const history = useHistory()

    const [state,setState] = useState({ 
        tab: 'search'
    })

    const handleScan = (resultQrcode) => {


        if(!resultQrcode) return;

        history.push(`/participante/${resultQrcode}`)
        // success({
        //     title: 'ok',
        //     message: resultQrcode
        // })

    }

    const setTab = (key) => {
        setState({
            tab:key
        })
    }

    return (
        <div style={styles.container}>
            <Reader></Reader>
            <Container>
                <Card className="mt-3" style={styles.card}>
                    <Card.Header>
                        <Nav onSelect={setTab} variant="tabs" className="justify-content-between" defaultActiveKey={state.tab}>
                            <Nav.Item>
                                <Nav.Link eventKey="qrcode">
                                    <FontAwesomeIcon size="2x" icon={faQrcode} />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="search" className="d-flex align-items-center">
                                    <FontAwesomeIcon size="2x" icon={faSearch} /> <span className="ml-2">Buscar na lista</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>
                    {state.tab === 'qrcode' ? 
                        (<>
                            <QrReader 
                                style={{ height:'100%' }}
                                onScan={handleScan}
                            />
                        </>
                        ) : <Card.Body>
                                <Search/>
                            </Card.Body>}
                </Card>
            </Container>
        </div>
    )

}

const styles ={
    container: {
        background: `url("${Background}")  0% 0% / cover`,
        minHeight: `100vh`
    }
}

export default Presencas
