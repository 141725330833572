import { SET_PARTICIPANTES,SET_LOADING,SET_PAGE } from '../actions/actionTypes'

const initialState = {
    participantes: null,
    loading: false,
    page: 1
}

export const participanteReducers = ( state = initialState, action ) => {

    switch (action.type) {
        case SET_PARTICIPANTES:
          return {
            ...state,
            participantes: action.payload
          };
        
        case SET_PAGE:
          return {
            ...state,
            page: action.payload
          };
        
        case SET_LOADING:
          return {
            ...state,
            loading: action.payload
          };
          
        default:
          return state;
      }

}