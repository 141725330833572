import React from 'react'
import {Navbar} from 'react-bootstrap'
import Logo from './Logo'

import { useHistory, Link } from "react-router-dom";

const Header = props => {
    
    let history = useHistory();

    function logOut(){

        localStorage.removeItem('token')
        history.push('/login')

    }

    return (
    <>
        <Navbar  className="text-white" bg="dark">
            <Navbar.Brand  className="text-white">
                <Link className="text-white" to="/"><Logo></Logo></Link>
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                <a href className="btn btn-link" onClick={logOut}>Sair</a>
            </Navbar.Collapse>
        </Navbar>
    </>
    )

}

export default Header;