import React, {useCallback,useState, useEffect} from 'react'
import {InputGroup,FormControl,Pagination} from 'react-bootstrap'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faKeyboard,faSpinner,faTimes } from '@fortawesome/free-solid-svg-icons'
import { debounce, get } from "lodash";

import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { searchParticipantes } from '../actions';

import Participantes from './Participantes'

const mapStateToProps = store => {
    return {
        participantes: store.participanteState.participantes,
        loading: store.participanteState.loading
    }
};

const mapDispatchToProps = dispatch => {

  const actions = bindActionCreators({ searchParticipantes }, dispatch)
//   actions.searchParticipantes()

  return actions;
};


function Search(props){

    const [state, setState] = useState({
        inputSearch: ''
    });

    useEffect(() => {
        search({
            search: '',
            page: 1
        })
    }, []);

    const search = (search) => {

        props.searchParticipantes(search)

    }
    
    const onSearchHandle = useCallback(debounce((inputSearch) => {
        search({
            search: inputSearch,
            page: 1
        })
    }, 600), []);

    const onSearch = (inputSearch) => {
        setState({
            inputSearch
        })
        onSearchHandle(inputSearch)
    }

    const reset = () => {
        setState({inputSearch:''})
        search({
            search: null,
            page: 1
        })
    }

    const participantes = () => {
        return get(props.participantes,'itens',null) ? props.participantes.itens : []
    }

    return (
    <>
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text id="search-icon">
                    <Icon icon={faKeyboard}></Icon>
                </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl 
                placeholder="Nome, documento"
                aria-label="Nome, documento"
                aria-describedby="search-icon"
                onChange={(e) => onSearch(e.target.value)}
                value={state.inputSearch}
                size="lg"
            ></FormControl>
            <InputGroup.Prepend>
                <InputGroup.Text id="search-reset" onClick={reset}>
                    <Icon icon={faTimes}></Icon>
                </InputGroup.Text>
            </InputGroup.Prepend>
        </InputGroup>
        {props.loading ? <div class="d-flex justify-content-center my-3">
            <Icon icon={faSpinner} size="2x" spin></Icon>
        </div> : <>
                {props.participantes && props.participantes.itens && props.participantes.itens.length > 0 ? <>
                    <Participantes participantes={participantes()} />
                    <hr/>
                    <div  class="d-flex justify-content-end">
                        <Pagination size="lg">
                            <Pagination.Prev onClick={() => {
                                search({
                                    search: state.inputSearch,
                                    page: props.participantes.primeira_anterior
                                })
                            }} />
                            <Pagination.Item active>{props.participantes.pagina_atual}</Pagination.Item>
                            <Pagination.Next onClick={() => {
                                search({
                                    search: state.inputSearch,
                                    page: props.participantes.proxima_pagina
                                })
                            }} />
                        </Pagination>
                    </div>
                </> : (
                    <div class="alert alert-info mt-2">Nenhum resultado</div>
                )}
        </>}
    </>
    )

}

export default connect(mapStateToProps, mapDispatchToProps)(Search)