import React from 'react'
import {ListGroup} from 'react-bootstrap'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faUserClock,faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from "react-router-dom";
import moment from 'moment'

export default props => {

    const list = () => props.participantes.map((participante,i) => {
            return (
                <ListGroup.Item action key={i}>
                    <div className="row">
                        <div className="col-3 col-md-auto col-auto d-flex align-items-center justify-content-center">
                            <Icon icon={faUserClock}></Icon>
                        </div>
                        <div className="col-9 col-md my-3 my-md-0">
                            <small>{participante.id}</small><br/>
                            <strong>{participante.type ? participante.type : 'INGRESSO'} - {participante.nome}</strong>
                            {participante.observacao ? (
                                <><br/>
                                    <small>
                                        <div className="bg-warning p-1 mt-2 rounded">
                                            <Icon icon={faInfoCircle}></Icon> 
                                            <span className="ml-2">{participante.observacao}</span>
                                        </div>
                                    </small>
                                </>
                            ) : null}
                        </div>
                        <div class="col d-flex align-items-center justify-content-end">
                            {participante.confirmado == 'nao' ? 
                            <Link class="btn btn-info btn-block" to={`/participante/${participante.id}-${participante.pagamento_id}`}>Validar</Link> : 
                            <div class="text-success text-right">Confirmado em {moment(participante.data_confirmacao).format('DD/MM/YYYY HH:mm')}</div>
                            }
                            
                        </div>
                    </div>
                    
                </ListGroup.Item>
            )
        })

    return <ListGroup className="mt-2">{list()}</ListGroup>;

}