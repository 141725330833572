import React from 'react';
import Routers from './Routers'
import './App.scss'
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
// import AlertTemplateMui from 'react-alert-template-oldschool-dark'

function App() {
  return (
    <AlertProvider template={AlertTemplate}>
        <Routers />
    </AlertProvider>
  );
}

export default App;