import React, {useState, useEffect} from 'react'

import {$http} from '../helpers'
import {success,danger,warning,loading} from '../helpers/alerts'
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import {Container,Form,Card,Button} from 'react-bootstrap'

import Background from '../images/background.jpg'
import Header from './Header'
import { useForm } from "react-hook-form";
import validateEmail from 'filter-validate-email'

import moment from 'moment'

export default function Participante(){

    let params = useParams();

    let participante_id = params.id

    let history = useHistory();
    const { register, handleSubmit, errors } = useForm();

    const [state, setState] = useState({
        nome: '',
        email: '',
        celular: '',
        observacao: '',
        loading: false
    });

    const searchByCode = async () => {

        const evento_key = process.env.REACT_APP_EVENTO_KEY

        loading('show')

        let response = await $http.get(`eventos/${evento_key}/participantes`,{
            params:{
                participante_id: participante_id
            }
        })

        if(response.status != 200 || !response.data){
            await warning({
                title: 'Houve alguma divergência',
            })
            history.push('/')
            return;
        }

        if(response.data.id !== participante_id || response.data.pagamento_id !== params.pagamento_id){
            await warning({
                title: 'Houve alguma divergência',
                message: 'O código não confere'
            })
            history.push('/')
            return;
        }

        if(response.data.confirmado != "nao"){
            await warning({
                title: '[Atenção] Já foi validado este acesso',
                message: `Horario ${moment(response.data.data_confirmacao).format('DD/MM/YYYY HH:mm')}`
            })
            history.push('/')
            return;
        }

        loading('hide')

        setState({ 
            loading: false,
            nome: response.data.nome,
            email: response.data.cadastro.email,
            celular: response.data.cadastro.celular,
            observacao: response.data.observacao,
            type: response.data.type,
        })

    }

    // quando o component é montado
    useEffect(() => {
        searchByCode()
    },[])

    const checkEmail = (value) => {
        if(!value) return;
        return validateEmail(value)
    }
    
    const validar = async () =>{

        loading('show')

        try {

            const evento_key = process.env.REACT_APP_EVENTO_KEY
            
            const response = await $http.put(`eventos/${evento_key}/participantes/confirmar`,{
                participante_id: params.id
            })

            if(response.status != 200) throw response.data

            await success({
                title: "Confirmado",
            })

            history.push("/")

        } catch (error) {
            warning({
                title:"Houve alguma divergência",
                message: error
            })
        }

    }

    return <>
        <div style={styles.container}>
            <Header></Header>
            <Container className="mt-3">
                <Card>
                    <Card.Body>
                        <h2>{state.type ? state.type : 'INGRESSO'}</h2>
                        <hr/>
                        <Form className="mt-2" onSubmit={handleSubmit(validar)}>
                            <Form.Group>
                                <Form.Label>
                                    Nome do participante <span class="text-danger">*</span>
                                </Form.Label>
                                <Form.Control disabled value={state.nome} isInvalid={errors.nome} name="nome" placeholder="Nome" ref={register({ required: true })}></Form.Control>
                            </Form.Group>
                            <Button size="lg" variant="success" type="submit">
                                Confirmar
                            </Button>
                        </Form>
                        
                    </Card.Body>
                </Card>
            </Container>
        </div>
    </>


}

const styles ={
    container: {
        background: `url("${Background}")  0% 0% / cover`,
        minHeight: `100vh`
    }
}