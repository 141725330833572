import axios from 'axios'

const $http = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    crossDomain: true,
    headers: {
       'X-Requested-With': 'XMLHttpRequest',
       'Content-Type': 'application/json',
       'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
    }
 })

 export {
    $http
 };