import { SET_PARTICIPANTES } from './actionTypes';
import { SET_LOADING } from './actionTypes';

import {$http} from '../helpers'
import _ from 'lodash'

const loading = (payload) => {
    return {
        type: SET_LOADING,
        payload: payload
    }
}

export const searchParticipantes = (options) => {
    return async (dispatch) => {
        
        const evento_key = process.env.REACT_APP_EVENTO_KEY

        dispatch(loading(true))

        let params = {}
        params.p = options.page ? options.page : 1

        if(options.search && /[^0-9.-]+/g.test(options.search)){
            params.nome = _.trim(options.search)
        }else if (options.search){
            params.documento = _.replace(_.trim(options.search), /[^0-9]+/g, '')
        }

        params.l = 20

        let response = await $http.get(`eventos/${evento_key}/participantes`,{
            params
        })

        dispatch(loading(false))
        
        dispatch({
            type: SET_PARTICIPANTES,
            payload: response.data
        })
    }
    
};
